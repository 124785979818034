import ReactPlayer from 'react-player'
import styles from "./tools.module.scss";
import titleBg from "../../assets/images/ai27_small_bg.png";

const Tools = ({ languageData }: { languageData: { [k: string]: string }}) => {
	return (
		<div className={styles.container_tools}>
			<div className={styles.header_container}>
				<div className={styles.header_title}>
					<img src={titleBg} alt="titleBg" />
					<span className={`${styles.title_bold} main_title`}>{languageData.title1} </span> <span className='main_title'>&nbsp;{languageData.title2}</span>
				</div>
				<div className={styles.header_subtile}><span>{languageData.subtitle}</span></div>
			</div>
			<div className={styles.body_container}>
				<div className={styles.video_row}>
					<div className={styles.video1}>
						<ReactPlayer width="100%" url='https://www.youtube.com/watch?v=qSLccO0M4qM' />
						<div className={styles.video_description_container}>
							<div className={styles.video_description}>
								<span>{languageData.cart1Title}</span>
								<span className='single_content'>{languageData.cart1Subtitle}</span>
							</div>
						</div>
					</div>
					<div className={styles.video2}>
						<ReactPlayer width="100%" url='https://www.youtube.com/watch?v=5SuPnVKQDBo' />
						<div className={styles.video_description_container}>
							<div className={styles.video_description}>
								<span>{languageData.cart2Title}</span>
								<span className='single_content'>{languageData.cart2Subtitle}</span>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.video_row}>
					<div className={styles.video3}>
						<ReactPlayer width="100%" url='https://www.youtube.com/watch?v=09S5apFmMxE' />
						<div className={styles.video_description_container}>
							<div className={styles.video_description}>
								<span>{languageData.cart3Title}</span>
								<span className='single_content'>{languageData.cart3Subtitle}</span>
							</div>
						</div>
					</div>
					<div className={styles.video4}>
						{/* <ReactPlayer width="100%" url='https://youtu.be/isl2y8aovLo?si=LsK2Q95AUEVLnGuc' /> */}
						<div className={styles.video_description_container}>
							<div className={styles.video_description}>
								<span>{languageData.cart4Title}</span>
								<span className='single_content'>{languageData.cart4Subtitle}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Tools;