
import styles from "./contact.module.scss";
import lastNewsLogo from "../../assets/images/lastNews_logo.png";
import xLogo from "../../assets/images/x_logo.png";
import facebookLogo from "../../assets/images/facebook_logo.png";
import linkInLogo from "../../assets/images/linkIn_logo.png";
import instagramLogo from "../../assets/images/instagram_logo.png";
import youtobeLogo from "../../assets/images/youtobe_logo.png";
import marker from "../../assets/images/marker.png";
import titleBg from "../../assets/images/ai27_small_bg.png";
import { ChangeEvent, useState } from "react";
import axios from "axios";

interface FormData {
	email: string;
	name: string;
	lastName: string;
	companyName: string;
	phone: string;
	activity: string;
	message: string;
}

const Contact = ({ languageData }: { languageData: { [k: string]: string } }) => {
	const emptyForm: FormData  = {
		email: '',
		name: '',
		lastName: '',
		companyName: '',
		phone: '',
		activity: '',
		message: '',
	}
	const [formData, setformData] = useState<FormData>(emptyForm);
	const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
	const [isSendingForm, setIsSendingForm] = useState<boolean>(false);
	const [message, setMessage] = useState<string>('');

	const validateForm = (d: FormData) => {
		const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (d.name !== '' && d.lastName !== '' && d.companyName !== '' && 
			d.phone !== '' && d.email !== '' && d.activity !== '') {
			setIsButtonEnabled(d.email.match(reg) !== null);
		} else {
			setIsButtonEnabled(false);
		}
	}

	const handleOnInputChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
		const { id, value }= e.target;
		setformData({
			...formData,
			[id]: value,
		} as FormData);

		validateForm({
			...formData,
			[id]: value,
		} as FormData);
	}
	
	const handleOnSelect = (e: ChangeEvent<HTMLSelectElement>) => {
		const { id, value } = e.target;
		setformData({
			...formData,
			[id]: value,
		} as FormData);

		validateForm({
			...formData,
			[id]: value,
		} as FormData);
	}

	const handleSendForm = async() => {
		setIsButtonEnabled(false);
		setIsSendingForm(true);
		try {
			const response = await axios({
				baseURL: 'https://www.ai27.com/v1/api/email/send',
				method: 'POST',
				data: {
					"from": formData.email,
					"parameters": {
						name: formData.name,
						lastName: formData.lastName,
						companyName: formData.companyName,
						phone: formData.phone,
						activity: formData.activity,
						message: formData.message,
					},
				}
			});
			setMessage(languageData.success);
			setformData(emptyForm);
			setTimeout(() => setMessage(''), 4000);
			return response;
		} catch (error) {
			setMessage(languageData.error);
			setTimeout(() => setMessage(''), 4000);
		} finally {
			setIsSendingForm(false);
			setIsButtonEnabled(true);
		}
	}

	const handleClickLinkIn = () => window.open("https://www.linkedin.com/company/89722997/admin/page-posts/published/");
	const handleClickYouToBe = () => window.open("https://www.youtube.com/@ai27predictiveintelligence.");
	const handleClickFacebook = () => window.open("https://www.facebook.com/profile.php?id=100087241471039");
	const handleClickX = () => window.open("https://x.com/Ai27Oficial");
	const handleClickInsta = () => window.open("https://www.instagram.com/ai27oficial/");

	return (
		<div className={styles.container_contact}>
			<div className={styles.contact_form}>
				<div className={styles.form_header}>
					<img src={titleBg} alt="titleBg" />
					<span className="main_title">{languageData.formTitle1} </span><span className="main_title"> {languageData.formTitle2}</span>
				</div>
				<div className={styles.form_body}>
					<div className={styles.form_row}>
						<input onChange={handleOnInputChange} value={formData.name} id="name" className={styles.form_input} type="text" placeholder={languageData.name}></input>
						<input onChange={handleOnInputChange} value={formData.lastName} id="lastName" className={styles.form_input} type="text" placeholder={languageData.lastName}></input>
					</div>
					<div className={styles.form_row}>
						<input onChange={handleOnInputChange} value={formData.companyName} id="companyName" className={styles.form_input} type="text" placeholder={languageData.company}></input>
						<input onChange={handleOnInputChange} value={formData.phone} id="phone" className={styles.form_input} type="text" placeholder={languageData.phone}></input>
					</div>
					<div className={styles.form_row}>
						<input onChange={handleOnInputChange} value={formData.email} id="email" className={styles.form_input} type="text" placeholder={languageData.email}></input>
						<select id="activity" defaultValue="0" value={formData.activity} onChange={handleOnSelect} className={styles.form_select}>
							<option value="0" >{languageData.opt0}</option>
							<option value={languageData.opt1Val}>{languageData.opt1}</option>
							<option value={languageData.opt2Val}>{languageData.opt2}</option>
							<option value={languageData.opt3Val}>{languageData.opt3}</option>
							<option value={languageData.opt4Val}>{languageData.opt4}</option>
						</select>
					</div>
					<div className={styles.form_row}>
						<textarea onChange={handleOnInputChange} value={formData.message} id="message" className={styles.form_text_area} placeholder={languageData.message}></textarea>
					</div>
				</div>
				<div className={styles.form_footer}>
					<button onClick={handleSendForm} disabled={!isButtonEnabled}>{isSendingForm ? languageData.sending : languageData.button}</button>
					{message !== '' && <span>{message}</span>}
				</div>
			</div>
			<div className={styles.cart}>
				<div className={styles.cart_shadow}>
					{/* <div className={styles.marker_container}>
						<img src={marker} alt="marker" />
						<div className={styles.address}>
							<span>Eje 5 Nte 990, Santa Barbara, Azcapotzalco, 02230 Ciudad de México, CDMX</span>
						</div>
					</div> */}
					<div className={styles.cart_title}>
						{/* <span>Ver últimos eventos</span> */}
						<span>{languageData.social}</span>
					</div>
					<div className={styles.cart_logo}>
						{/* <img src={lastNewsLogo} className={styles.news_logo} alt="lastNewsLogo" /> */}
						<img onClick={handleClickLinkIn} src={linkInLogo} alt="linkInLogo" />
						<img onClick={handleClickYouToBe} src={youtobeLogo} alt="youtobeLogo" />
						<img onClick={handleClickFacebook} src={facebookLogo} alt="facebookLogo" />
						<img onClick={handleClickX} src={xLogo} alt="xLogo" />
						<img onClick={handleClickInsta} src={instagramLogo} alt="instagramLogo" />
					</div>
				</div>
			</div>
		</div>
	);
}
export default Contact;
