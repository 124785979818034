
import styles from "./footer.module.scss";
import footerLogo from "../../assets/images/footer_logo2.png";

interface FooterProps {
	scrollToTools: () => void;
	scrollToProducts: () => void;
	scrollToCoverage: () => void;
	scrollToContact: () => void;
	languageData: { [k: string]: string };
}

const Footer = ({
	scrollToTools,
	scrollToProducts,
	scrollToCoverage,
	scrollToContact,
	languageData }:FooterProps
) => {

	const handleClickTerms = () => window.open("https://ai27.com/Terminos.html");
	const handleClickPrivacy = () => window.open("https://ai27.com/Aviso.html");

	return (
		<div className={styles.container_footer}>
			<div className={styles.menu_container}>
				<div className={styles.menu} onClick={scrollToTools}>
					<span className={styles.title}>{languageData.menu1Title}</span>
					<span>{languageData.menu1Subtitle1}</span>
					<span>{languageData.menu1Subtitle2}</span>
					<span>{languageData.menu1Subtitle3}</span>
					<span>{languageData.menu1Subtitle4}</span>
				</div>
				<div className={styles.menu} onClick={scrollToProducts}>
					<span className={styles.title}>{languageData.menu2Title}</span>
					<span>{languageData.menu2Subtitle1}</span>
					<span>{languageData.menu2Subtitle2}</span>
				</div>
				<div className={styles.menu}>
					<span className={styles.title}>{languageData.menu3Title}</span>
					<span onClick={handleClickTerms}>{languageData.menu3Subtitle3}</span>
					<span onClick={handleClickPrivacy}>{languageData.menu3Subtitle4}</span>
				</div>
				<div className={styles.menu}>
					<span className={styles.title} onClick={scrollToContact}>{languageData.menu4Title}</span>
					<span>{languageData.menu4Subtitle1}</span>
					<span>
						{languageData.menu4Subtitle2}<br/>
						{languageData.menu4Subtitle3}<br/>
						{languageData.menu4Subtitle4}<br/>
						{languageData.menu4Subtitle5}<br/>
						{languageData.menu4Subtitle6}
					</span>
					<span>{languageData.menu4Subtitle7}</span>
				</div>
				<div className={styles.last_menu}>
					<div className={styles.footer_logo}>
						<img src={footerLogo} alt="footerLogo" />
					</div>
				</div>
			</div>
		</div>
	);
}
export default Footer;