import ReactPlayer from 'react-player'
import styles from "./products.module.scss";
import secureLogo from "../../assets/images/secure-mobility-logo.png";
import ainsuranceLogo from "../../assets/images/ainsurance_logo.png";
import titleBg from "../../assets/images/ai27_small_bg.png";
import titleWhiteBg from "../../assets/images/ai27_small_white_bg.png";
import titleBlueBg from "../../assets/images/ai27_small_blue_bg.png";
import productbg1 from "../../assets/images/productbg1.png";
import productbg2 from "../../assets/images/productbg2.png";

const Products = ({ languageData, scrollToContact }: { languageData: { [k: string]: string }, scrollToContact: () => void }) => {

	const handleOnClick = () => window.open("https://www.ais-secure.com/shopping?level=lvl3Combo");

	return (
		<div className={styles.container_products}>
			<div className={styles.header_container}>
				<div className={styles.header_title}>
					<img src={titleBg} alt="titleBg" />
					<span className='main_title'>{languageData.title1} </span><span className='main_title'> {languageData.title2}</span>
				</div>
				<div className={styles.header_subtile}><span>{languageData.subtitle1} {languageData.subtitle2}</span></div>
			</div>
			<div className={styles.body_container}>
				<div className={styles.video_row}>
					<div className={styles.cart_container}>
						<div className={styles.cart_header}>
							<div>
								<img src={titleWhiteBg} alt="titleWhiteBg"/>
								<span className='main_title'>{languageData.cart1Title1} </span> <span className={`${styles.cart_header_subtitle} main_title`}>{languageData.cart1Title2}</span>
							</div>
							<div className={styles.cart_header_img}><img src={secureLogo} alt="secur-logo" /></div>
						</div>
						<div className={styles.cart_body}>
							<p className='single_content'>{languageData.cart1Content1}</p>
							<p className='single_content'>{languageData.cart1Content2}</p>
							<p className='single_content'>{languageData.cart1Content3}</p>
							<p className='single_content'>{languageData.cart1Content4}</p>
						</div>
						<div className={styles.cart_footer}>
							<button className={styles.first_btn}>{languageData.cart1Button1}</button>
							<button onClick={handleOnClick} className={styles.second_btn}>{languageData.cart1Button2}</button>
						</div>
					</div>
					<div className={styles.video}>
						<img src={productbg1} />
						{/* <ReactPlayer width="100%" url='https://youtu.be/vv8bcpym14Q?si=IKDAgFqPfiuztIPI' /> */}
					</div>
				</div>
				<div className={styles.video_row}>
					<div className={`${styles.cart_container} ${styles.cart_container_blue}`}>
						<div className={styles.cart_header}>
							<img src={titleBlueBg} alt="titleBlueBg" />
							<span className='main_title'>{languageData.cart2Title}</span>
							<div className={styles.cart_header_img}><img src={ainsuranceLogo} alt="ainsurance-logo" /></div>
						</div>
						<div className={styles.cart_body}>
							<p className='single_content'>{languageData.cart2Content1}</p>
							<p className='single_content'>{languageData.cart2Content2}</p>
							<p className='single_content'>{languageData.cart2Content3}</p>
						</div>
						<div className={styles.cart_footer}>
							<button className={`${styles.first_btn} ${styles.third_btn}`}>{languageData.cart2Button1}</button>
							<button onClick={scrollToContact} className={`${styles.second_btn} ${styles.third_btn}`}>{languageData.cart2Button2}</button>
						</div>
					</div>
					<div className={styles.video}>
						<img src={productbg2}/>
						{/* <ReactPlayer width="100%" url='https://youtu.be/8vlW9x2oA3w?si=XE9LqBFotVUrrkOt' /> */}
					</div>
				</div>
			</div>
		</div>
	);
}
export default Products;